<template>
<div>
  <Form :model="detail" :label-width="100" ref="quanForm">
    <FormItem label="内容" v-if="detail.quan_content">
      <Input v-model="detail.quan_content" style="width: 500px" type="textarea" readonly></Input>
    </FormItem>
    <FormItem label="图片" v-if="detail.pic.length > 0">
      <div class="demo-upload-list" v-for="item in detail.pic" :key="item.quan_pic_id">
        <img :src="item.quan_pic_url" @click="handleView(item.quan_pic_url)">
      </div>
    </FormItem>
    <FormItem label="赞数">
      <Input v-model="detail.quan_like" style="width: 200px" readonly></Input>
    </FormItem>
    <FormItem label="评论数">
      <Input v-model="detail.quan_comment" style="width: 200px" readonly></Input>
      <span style="color: #2d8cf0" @click="showPinglun"> 查看评论</span>
    </FormItem>
    <FormItem label="转发数">
      <Input v-model="detail.quan_forward" style="width: 200px" readonly></Input>
    </FormItem>
    <FormItem label="状态">
      <span v-if="detail.quan_hidden === 1">隐藏</span>
      <span v-else>正常</span>
    </FormItem>

    <FormItem label="转发自" v-if="detail.from_user_nick">
      <Input v-model="detail.from_user_nick" style="width: 200px" readonly></Input>
    </FormItem>

    <FormItem label="对谁可见">
      <span v-if="detail.quan_who_see === 'all'">全部可见</span>
      <span v-else>关注可见</span>
    </FormItem>

    <FormItem label="谁可评论">
      <span v-if="detail.quan_who_comment === 'all'">全部可评论</span>
      <span v-else>关注可评论</span>
    </FormItem>

  </Form>

  <Modal title="View Image" v-model="visible">
    <img :src="imgName" v-if="visible" style="width: 100%">
  </Modal>

  <Modal title="查看评论" v-model="pinglunShow" :mask-closable="false" :footer-hide="true">
    <List>
      <ListItem v-for="item in comment" :key="item.quan_comment_id">
        <ListItemMeta :description="item.quan_comment_content">
          <template slot="title">
            <span>{{item.from_user_nick}}</span>
            <span v-if="item.to_user_nick"> 回复 </span>
            <span v-if="item.to_user_nick">{{item.to_user_nick}}</span>
            <span>：</span>
          </template>
        </ListItemMeta>
        <template slot="action">
          <li>
            <a @click="delComment(item.quan_comment_id)">删除</a>
          </li>
        </template>
      </ListItem>
    </List>
    <Page show-total :total="count" :current="page" :page-size="30" @on-change="pageChange" size="small"/>
  </Modal>
</div>
</template>

<script>
import api from "../../api/xiaochengxu";
export default {
  name: "QuanDetail",
  data: function () {
    return {
      detail: {},
      quan_id: '',
      visible: false,
      imgName: '',
      pinglunShow: false,
      comment: [],
      count: 0,
      page: 1
    }
  },
  methods: {
    delComment: function(id){
      this.$Modal.confirm({
        content: '<p>确实要删除这条评论吗？</p>',
        onOk: () => {
          this.$http.delete(api.delComment, {data: {id:id}}).then(response => {
            if(response.data.success) {
              this.$Message.success('操作成功');
              this.getPinglunList();
            } else {
              this.$Message.error(response.data.err);
              console.log(response);
            }
          })
        },
      });
    },
    pageChange: function(p){
      this.page = p;
      this.getPinglunList();
    },
    handleView (name) {
      this.imgName = name;
      this.visible = true;
    },
    showPinglun: function(){
      this.getPinglunList()
      this.pinglunShow = true
    },
    getPinglunList: function(){
      this.$http.get(api.quanComment, {params: {id: this.quan_id}}).then(response => {
        if(response.data.success) {
          this.comment = response.data.res;
          this.count = response.data.count
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    }
  },
  mounted() {
    this.$emit('lastPage', '/xiaochengxu/quan')
    this.quan_id = this.$route.query.id;
    this.$http.get(api.quanDetail, {params: {id: this.quan_id}}).then(response => {
      if(response.data.success) {
        this.detail = response.data.res;
      } else {
        this.$Message.error(response.data.err);
        console.log(response)
      }
    })
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>
.demo-upload-list{
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0,0,0,.2);
  margin-right: 4px;
}
.demo-upload-list img{
  width: 100%;
  height: 100%;
}
</style>